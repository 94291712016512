<template>
    <div v-if="edtCartouche.version==1">
        <collapse-panel class="cartouche" :title="$t('note-editor.compulsory.title')" :visible="compulsoryPanelVisible" @expand="compulsoryPanelVisible=true" @collapse="compulsoryPanelVisible=false">
            <b-container>
                <score-row class="even" v-model="edtCartouche.isExecCorrect" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.isExecCorrect")}}
                </score-row>
                <score-row class="odd" v-model="edtCartouche.isWellSized" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.isWellSized") }}
                </score-row>
                <div style="display: flex; justify-content: center">
                    <b-checkbox switch v-model="compulsoryIncludeModel" inline>{{ $t('note-editor.compulsory.diagram-image') }}</b-checkbox>
                    <b-checkbox switch v-model="compulsoryIncludeText" inline>{{ $t('note-editor.compulsory.diagram-details') }}</b-checkbox>
                </div>
                <compulsory-image :includeModel="compulsoryIncludeModel" :includeText="compulsoryIncludeText" v-model="d_compulsory_code" />
            </b-container>
        </collapse-panel>
        <slot name="between-compulsory-and-comments"></slot>
        <collapse-panel title="Notes" :visible="commentsPanelVisible" @expand="commentsPanelVisible=true" @collapse="commentsPanelVisible=false">
            <b-textarea v-model="edtCartouche.comments"></b-textarea>
        </collapse-panel>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CollapsePanel from '@/components/Panels/CollapsePanel.vue';
import ScoreRow from './ScoreDetailDecisionHelperRow.vue';
import CompulsoryImage from '../CompulsoryImage.vue';

export default {
    components:{ CollapsePanel, ScoreRow, CompulsoryImage },
    props: {
        value: { type: Object, required: true},
        readonly: { type: Boolean, default: false},
        compulsory_code: { type: String, required: true},
    },
    data(){
        return {
            edtCartouche: this.value,
            options: [
                { text: this.$t("note-editor.criteria-appreciation.yes"), value: "yes"},
                { text: this.$t("note-editor.criteria-appreciation.no"), value: "no"},
                { text: this.$t("note-editor.criteria-appreciation.partially"), value: "partially"},
            ],
            d_compulsory_code: this.compulsory_code,
        };
    },
    computed:{
        ...mapGetters('config', ['cfgCompulsoryNoteEditor_compulsoryPaneVisible', 'cfgCompulsoryNoteEditor_compulsoryPaneIncludeModel', 'cfgCompulsoryNoteEditor_compulsoryPaneIncludeText', 'cfgCompulsoryNoteEditor_commentsPaneVisible']),

        compulsoryPanelVisible:{
            get: function() { return this.cfgCompulsoryNoteEditor_compulsoryPaneVisible; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_compulsoryPaneVisible (newVal);}
        },
        compulsoryIncludeModel:{
            get: function() { return this.cfgCompulsoryNoteEditor_compulsoryPaneIncludeModel; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_compulsoryPaneIncludeModel (newVal);}
        },
        compulsoryIncludeText:{
            get: function() { return this.cfgCompulsoryNoteEditor_compulsoryPaneIncludeText; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_compulsoryPaneIncludeText (newVal);}
        },
        commentsPanelVisible:{
            get: function() { return this.cfgCompulsoryNoteEditor_commentsPaneVisible; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_commentsPaneVisible (newVal);}
        },
    },
    methods:{
        ...mapMutations('config', ['setCfgCompulsoryNoteEditor_compulsoryPaneVisible', 'setCfgCompulsoryNoteEditor_compulsoryPaneIncludeModel', 'setCfgCompulsoryNoteEditor_compulsoryPaneIncludeText', 'setCfgCompulsoryNoteEditor_commentsPaneVisible', ]),
        emit_change(){
            this.$emit("change");
        }
    },
    watch:{
        value : (newValue) => {
            this.edtCartouche = newValue;
        },
        compulsory_code : (newValue) => {
            this.d_compulsory_code = newValue;
        }
    }
}
</script>