<template>
    <div class="score-detail-decision-row" @click="reset">
        <div class="label">
            <slot></slot>
        </div>
        <div class="separator" style="width:20px; background-color: red;" @click="(e)=>e.preventDefault">&nbsp;</div>
        <div class="decisions">
            <b-radio-group 
                v-model="Value"
                :options="options" buttons button-variant="outline-secondary" 
                :disabled="readonly"
            >
            </b-radio-group>
        </div>
    </div>
</template>

<script>
export default {
    model:{
        prop: 'value',
        event: 'change'
    },
    emits:['change'],
    props:{
        value: {type: String, default:''},
        options: {type: Array, default:null},
        readonly: {type: Boolean, default: false},
    },
    data(){
        return {
            d_value: this.value,
            d_readonly: this.readonly
        };
    },
    computed:{
        Value:{
            get(){ return this.d_value;},
            set(newValue){
                this.$emit('change', newValue);
            }
        },
        Readonly(){ return this.d_readonly;}
    },
    methods:{
        reset(){ 
            if(!this.readonly) this.d_value = null;
        }
    },
    watch:{
        value(newValue){
            this.d_value = newValue;
        },
        readonly(newValue){
            this.d_readonly = newValue;
        }
    }
}
</script>

<style scoped>

.score-detail-decision-row{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding-left:5px;
    padding-right:1px;
}
.score-detail-decision-row .decisions{
    justify-self: flex-end;
}
</style>

